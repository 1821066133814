@import "styles/abstracts/index";
.title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 144%;

  text-align: center;
  letter-spacing: -0.5px;

  color: var(--black);
}
@include md-down() {
  .title {
    font-size: 2rem;
  }
}
