@import "styles/abstracts/index";
.wrapper {
  @include md-down() {
    gap: 1.5rem;
  }
}

.slogan {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 120%;
  text-align: center;
  color: var(--black-light);
  opacity: 0.8;
}

.border {
  display: block;
  margin: 0 auto;
  width: 80%;
  border-bottom: 2px dashed var(--black-dark);
}
