@import "styles/abstracts/index";
.wrapper {
  @include md-down() {
    gap: 15px;
  }
}

.title {
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 192%;
  letter-spacing: -0.5px;
  color: var(--black-dark);
  text-align: center;
}

@include md-down() {
  .title {
    font-size: 1.5rem;
  }
}
