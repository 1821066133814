@import "styles/abstracts/index";
.wrapper {
  font-size: 1.25rem;

  @include md-down() {
    font-size: 1rem;
  }

  & svg {
    color: var(--yellow-dark);
  }
}

.text {
  color: var(--black);
}
