@import "styles/abstracts/index";
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10rem;

  & > div {
    width: 40%;
  }

  & > form {
    width: 60%;
  }

  @include xl-down() {
    gap: 5rem;
  }

  @include lg-down() {
    flex-direction: column-reverse;
    gap: 2rem;

    & > div,
    & > form {
      width: 100%;
    }
  }
}
